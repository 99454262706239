<template>
  <div>
    <el-container>
      <el-header
        class="header"
        height="80px"
      >
        <nav class="average">
          <el-menu
            :default-active="activeIndex2"
            class="el-menu-demo line"
            mode="horizontal"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
          >
            <el-menu-item class="logo">
              {{ title }}
            </el-menu-item>
            <el-menu-item>
              <router-link to="/home">
                首页
              </router-link>
            </el-menu-item>
            <el-menu-item>
              <router-link to="/images">
                图片编码
              </router-link>
            </el-menu-item>
            <el-sub-menu>
              <template #title>
                二维码
              </template>
              <el-menu-item>
                <router-link to="/deqr">
                  二维码解码
                </router-link>
              </el-menu-item>
              <el-menu-item>
                <router-link to="/ecqr">
                  生成二维码
                </router-link>
              </el-menu-item>
            </el-sub-menu>
            <el-menu-item>
              <router-link to="/online_editor">
                在线编辑器
              </router-link>
            </el-menu-item>
            <el-menu-item>
              <router-link to="/about">
                关于
              </router-link>
            </el-menu-item>
          </el-menu>
        </nav>
        <div class="xiazai">
          <el-button
            type="warning"
            @click="sodow"
          >
            <el-icon>
              <Download />
            </el-icon>
            加密组件下载
          </el-button>
        </div>
      </el-header>
      <el-main class="main">
        <router-view />
      </el-main>
      <el-footer
        class="foorer"
        height="120px"
      >
        <div class="hang">
          Copyright © 2024
          <a
            href="https://www.eyouphp.com"
            target="_blank"
          >
            EyouPHP
          </a>
        </div>
        <br>
        <div class="hang">
          Powered by EyouPHP 7.3.0
        </div>
        <div class="hang">
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
          >
            赣ICP备2022000163号
          </a>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
 import {title, apihost} from '@/config/app'
 import { ElLoading, ElMessage } from "element-plus";
 import axios from "axios";
 import download from 'downloadjs';
 export default {
   name: 'LayoutViews',
   data(){
      return {
        activeIndex2 : '1',
        title: title,
      }
   },
   methods:{
     async sodow(){
       let loading = ElLoading.service();
       await axios.get(`${apihost}/api/ecodes/getso`, {responseType : 'blob'}).then(res => {
         loading.close();
         download(res.data, 'eyouphp_loader.so');
       }).catch(err => {
         loading.close();
         ElMessage.error('获取so文件失败:' + err);
       })
     }
   }
 }

</script>
<style>
.header{
  padding: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.logo{
  padding: 0 20px;
  font-size: 20px;
  line-height: 80px;
  color: #fff;
  text-decoration: none;
}
.foorer{
  background-color: #f1eeee;
  color: #838282;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  margin-top: 100px;
}
.hang{
  display: block !important;
  margin: 0 30px;
}
.foorer a{
  color: #838282;
  text-decoration: none;
}
.main{
  width: 100%;
  max-width: 1200px;
  margin: 80px auto 150px auto;
}
.xiazai{
  position: absolute;
  right: 100px;
  top: 12px;
}
a{
  color: #fff;
  margin-right: 20px;
  text-decoration: none;
}
</style>