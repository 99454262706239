import Layout from '@/Layout/index.vue'
import {title} from '@/config/app';
export const routes = [
    {
        path : '/',
        component : Layout,
        redirect : '/home',
        children : [
            {
                path : '/home',
                name : 'home',
                meta : {
                    title : `首页 - ${title}`,
                },
                component : () => import('@/views/HomeView.vue')
            },
            {
                path : '/about',
                name : 'about',
                meta : {
                    title : `关于 - ${title}`,
                },
                component : () => import('@/views/AboutView.vue')
            },
            {
                path: '/images',
                name : 'images',
                meta : {
                    title : `图片Base64编码 - ${title}`,
                },
                component : () => import('@/views/ImagesView.vue')
            },
            {
                path: '/deqr',
                name : 'deqr',
                meta : {
                    title : `二维码解码 - ${title}`,
                },
                component : () => import('@/views/DeqrView.vue')
            },
            {
                path: '/ecqr',
                name : 'ecqr',
                meta : {
                    title : `生成二维码 - ${title}`,
                },
                component : () => import('@/views/AesQrcodeView.vue')
            },
            {
                path: '/online_editor',
                name : 'online_editor',
                meta : {
                    title : `在线编辑器 - ${title}`,
                },
                component : () => import('@/views/OnlineEditorView.vue')
            },
            {
                path : '/:pathMatch(.*)*',
                name : 'notFound',
                meta : {
                    title : `404 - ${title}`,
                },
                component : () => import('@/views/404View.vue')
            }
         ]
    }

]